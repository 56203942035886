import React, { Component } from "react";
import App from "../../App";
import { AiOutlineArrowLeft } from "react-icons/all";
import { Link, withRouter } from "react-router-dom";
import Loader from "react-loader";
import { getData } from "../../services/baseServices";
import moment from "moment";
import { getWarehouseType, humanize } from "../../helper/utils";

class Details extends Component {
  state = {
    raDetail: {},
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    getData(`retailer_assistants/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ raDetail: res?.data, loading: false });
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
        this.setState({ loading: false });
      });
  }

  render() {
    const { raDetail } = this.state;

    return (
      <App layout="">
        <div className="page-header">
          <h2 className="page-title">
            Sales Representatives Details ({raDetail?.name})
          </h2>
          <div className="ml-auto">
            <Link to="/ra/list" className="btn btn-sm btn-link mr-2">
              <AiOutlineArrowLeft /> Back to List
            </Link>
          </div>
        </div>

        <div className="main-content">
          <Loader loaded={!this.state.loading}>
            <div className="card-block">
              <div className="block-body">
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>Name</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{raDetail?.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Phone</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{raDetail?.phone}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Category</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{humanize(raDetail?.category)}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Sales Mode</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {raDetail?.sales_mode
                          ?.split("_")
                          ?.map((word) => word.toUpperCase())
                          .join(" ")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Date of Birth</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{moment(raDetail?.date_of_birth).format("D/M/Y")}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Father's Name</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{raDetail?.father_name}</td>
                    </tr>
                    {/* <tr>
                                  <td style={{width: '250px'}}>Address</td>
                                  <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                  <td>{raDetail?.address}</td>
                              </tr> */}
                    <tr>
                      <td style={{ width: "250px" }}>NID</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{raDetail?.nid}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Education</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{raDetail?.education}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Tech Savvyness</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{raDetail?.tech_skill}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Experience</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{raDetail?.experience}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{raDetail?.status}</td>
                    </tr>
                    {getWarehouseType() === "central" && (
                      <tr>
                        <td style={{ width: "250px" }}>Created By</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{raDetail?.created_by?.name}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Loader>
        </div>
      </App>
    );
  }
}

export default withRouter(Details);
