import { Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import App from "../../App";
import { getDetails } from "../../services";
import ImageCarousel from "./ImageCarousel";

const OutletSurveyDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [surveyDetails, setSurveyDetails] = useState({});
  const getOutletSurveyDetails = (id) => {
    getDetails("surveys", id)
      .then((res) => {
        if (res.success) {
          setSurveyDetails(res.data[0]);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to fetch outlet survey details");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOutletSurveyDetails(id);
  }, [id]);

  const { photos } = surveyDetails;

  return (
    <App layout={`boxed`}>
      <div className="main-content">
        <div className="page-header">
          <div className="page-title">Survey Details</div>
          <div className="ml-auto">
            <Link to={`/outlet/survey`} className="btn btn-sm btn-link">
              <FaLongArrowAltLeft /> Back to Survey List
            </Link>
          </div>
        </div>
        {isLoading ? (
          <Spin fullscreen />
        ) : (
          <div className="d-flex justify-content-between">
            <div className="container">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-center">Details Info</h5>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">{`Outlet Name : ${surveyDetails?.outlet?.name}`}</li>
                  <li class="list-group-item">{`Outlet Phone : ${surveyDetails?.outlet?.phone}`}</li>
                  <li class="list-group-item">{`Created By : ${surveyDetails?.created_by}`}</li>
                  <li class="list-group-item">{`Created At : ${moment(
                    surveyDetails?.created_at
                  ).format("DD-MM-YYYY")}`}</li>
                  <li class="list-group-item">{`Comment : ${surveyDetails?.remarks}`}</li>
                </ul>
              </div>
            </div>

            <div className="container ms-auto">
              {photos && photos.length > 0 ? (
                <ImageCarousel photos={photos} />
              ) : (
                <p>No photos available</p>
              )}
            </div>
          </div>
        )}
      </div>
    </App>
  );
};

export default OutletSurveyDetails;
