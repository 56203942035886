import React, { Component } from "react";
import App from "../../App";
import Loader from "react-loader";
import { FiEdit, FiPlus } from "react-icons/all";
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import ConfirmationModal from "../common/Modal";
import {
  getPaginatedList,
  destroy,
  getList,
} from "../../services/baseServices";
import {
  getWarehouseType,
  humanize,
  getBusinessType,
} from "../../helper/utils";
const businessType = getBusinessType();
class List extends Component {
  constructor() {
    super();
    this.state = {
      raList: [],
      activePage: 1,
      per_page: 15,
      page: 1,
      temp_params: {},
      query_params: {},
      distributors: [],
      loading: false,
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  getPaginatedRAs = (params) => {
    this.setState({ loading: true });
    getPaginatedList("retailer_assistants", params)
      .then((res) => {
        this.setState({
          raList: res.data,
          per_page: res.headers["x-per-page"],
          total_data: res.headers["x-total"],
          loading: false,
        });
      })
      .catch((errMsg) => {
        this.setState({ loading: false });
      });
  };

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    this.setState({ ...this.state, page: 1, query_params: tempParam }, () => {
      this.getPaginatedRAs(this.state.temp_params);
    });
  };

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {});
  };

  handleOnClear = () => {
    this.setState(
      {
        temp_params: {
          ...this.state.temp_params,
          title: "",
          distributor_id: "",
        },
        query_params: {
          ...this.state.temp_params,
          title: "",
          distributor_id: "",
        },
      },
      () => {
        this.getPaginatedRAs(this.getQueryParams());
      }
    );
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedRAs(this.getQueryParams());
    });
  };

  componentDidMount() {
    this.getPaginatedRAs(this.getQueryParams());
    getList("distributors")
      .then((res) => {
        this.setState({ distributors: res?.data });
      })
      .catch((errMsg) => {});
  }

  handleDeleteAction = (id) => {
    destroy("retailer_assistants", id)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success("Successfully deleted");
          this.getPaginatedRAs(this.getQueryParams());
        } else {
          toast.error(res?.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  render() {
    return (
      <App layout={""}>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">
              {businessType === "multicat"
                ? "Sales Representative List"
                : "Sales Representatives List"}
            </h2>
            <div className="ml-auto">
              {getWarehouseType() != "central" && (
                <Link
                  to={`${process.env.PUBLIC_URL}/ra/add`}
                  className="btn btn-sm btn-primary"
                >
                  <FiPlus /> Add
                </Link>
              )}
            </div>
          </div>
          <div align="center">
            <form className="form-inline mb-3">
              <div className="form-group mr-3">
                <label htmlFor="distributor_id" className="sr-only">
                  Distributor
                </label>
                <select
                  id="distributor_id"
                  name="distributor_id"
                  className="form-control form-control-sm"
                  onChange={this.handleOnFieldChange}
                  value={this.state.temp_params?.distributor_id}
                >
                  <option value="">--Select Distributor--</option>
                  {this.state.distributors.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <Button
                size="sm"
                type={`submit`}
                onClick={this.handleOnSearch}
                variant="primary"
              >
                Filter
              </Button>
              <Button
                size="sm"
                type={`button`}
                className="ml-2"
                onClick={this.handleOnClear}
                variant="danger"
              >
                Clear
              </Button>
            </form>
          </div>
          <div className="card-block">
            <div className="block-body">
              <Loader loaded={!this.state.loading}>
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>#</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Sales Mode</th>
                      <th>Category</th>
                      <th>Status</th>
                      {getWarehouseType() != "central" && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.raList &&
                      this.state.raList?.map((ra, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {(this.state.page - 1) * this.state.per_page +
                                index +
                                1}
                            </td>
                            <td className="text-break">
                              <Link
                                to={`${process.env.PUBLIC_URL}/ra/details/${ra.id}`}
                              >
                                {ra.name}
                              </Link>
                            </td>
                            <td>{ra.phone}</td>
                            <td className="text-break">
                              {ra.sales_mode
                                ?.split("_")
                                ?.map((word) => word.toUpperCase())
                                .join(" ")}
                            </td>
                            <td>{humanize(ra.category)}</td>
                            <td>{ra.status}</td>
                            {getWarehouseType() != "central" && (
                              <td style={{ width: "150px" }}>
                                <div className="btn-group">
                                  <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        Edit
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/ra/edit/${ra.id}`}
                                      className="btn btn-sm btn-default"
                                    >
                                      <FiEdit className="text-info" />
                                    </Link>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        Delete
                                      </Tooltip>
                                    }
                                  >
                                    <ConfirmationModal
                                      title="Delete"
                                      body="Are you sure you want to delete?"
                                      handleAction={() =>
                                        this.handleDeleteAction(ra.id)
                                      }
                                    />
                                  </OverlayTrigger>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </Loader>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(List);
