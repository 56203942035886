import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import App from "../../App";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import {
  getThanas,
  getAreas,
  getList,
  getData2,
  putData2,
} from "../../services/baseServices";
import { getLocalAuthToken, humanize } from "../../helper/utils";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getWarehouseType } from "../../helper/utils";
import DatePicker from "react-datepicker";
const district = getLocalAuthToken()?.message?.district_id;

const Edit = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [thanas, setThanas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState(0);
  const [thana, setThana] = useState(0);
  const [distributors, setDistributors] = useState([]);

  const history = useHistory();
  const { id } = useParams();

  const schema = yup.object().shape({
    category: yup.string().required("Please enter category."),
    distributor_id: yup.string().required("Please enter Distributor."),
    sales_mode: yup.string().required("Please enter sales mode."),
    name: yup.string().required("Name is a required"),
    fathers_name: yup
      .string()
      .required("Fathers Name is a required")
      .matches(
        /^[a-zA-Z]+(\.?)(\s[a-zA-Z]+(\.?))*$/,
        "No special characters and numbers are allowed"
      ),
    email: yup.string().email("Please provide valid email"),
    date_of_birth: yup.string().required(),
    phone: yup
      .string()
      .required("Phone is required")
      .matches(
        /(^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$/,
        "Please provide valid BD phone number"
      ),
    education: yup.string().required("Education is required").trim(),
    tech_skill: yup.string().required("Tech savvyness is required").trim(),
    experience: yup.string().required("Experience is required").trim(),
    thana_id: yup.string().required("Please enter thana."),
    area_id: yup.string().required("Please enter area."),
    address_line: yup.string().required("Please enter address line.").trim(),
    status: yup.string().required("Status is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const selected_distributor = watch("distributor_id", "");

  useEffect(() => {
    selected_distributor &&
      getThanas({ distributor_id: selected_distributor })
        .then((res) => {
          setThanas(res);
          setValue("area_id", "");
          setAreas([]);
        })
        .catch((errMsg) => {});
  }, [selected_distributor]);

  useEffect(() => {
    getList("retailer_assistants/ra-categories")
      .then((res) => {
        setCategories(res);
      })
      .catch((errMsg) => {});

    getList("distributors").then((res) => {
      setDistributors(res?.data);
    });
  }, []);

  useEffect(() => {
    getData2(`retailer_assistants/${id}`)
      .then((res) => {
        setLoading(true);
        callAreas(res?.data?.address?.thana_id);
        setThana(res?.data?.address?.thana_id);
        setArea(res?.data?.address?.area_id);
        reset({
          category: res?.data?.category,
          sales_mode: res?.data?.sales_mode,
          name: res?.data?.name,
          fathers_name: res?.data?.father_name,
          email: res?.data?.email,
          date_of_birth: new Date(res?.data?.date_of_birth),
          phone: res?.data?.phone,
          nid: res?.data?.nid,
          education: res?.data?.education,
          tech_skill: res?.data?.tech_skill,
          experience: res?.data?.experience,
          address_line: res?.data?.address?.address_line,
          thana_id: res?.data?.address?.thana_id,
          area_id: res?.data?.address?.area_id,
          status: res?.data?.status,
          distributor_id: res?.data?.distributor_id,
        });

        setLoading(false);
      })
      .catch((errMsg) => {
        setLoading(false);
      });
  }, [id, reset]);

  const selected_thana = watch("thana_id", "");
  useEffect(() => {
    if (!selected_thana) {
      setValue("area_id", "");
      setArea("");
      setAreas([]);
    } else if (selected_thana && selected_thana > 0) {
      callAreas(selected_thana);
      if (selected_thana !== thana) {
        setValue("area_id", "");
        setArea("");
      }
    }
    setThana(selected_thana);
  }, [selected_thana]);

  const callAreas = (thana_id) => {
    getAreas({ thana_id: thana_id })
      .then((res) => {
        setAreas(res);
        setValue("area_id", res.find((item) => item.id === area)?.id);
      })
      .catch((errMsg) => {});
  };

  const onSubmit = (data) => {
    let phone_no = "";
    if (data.phone.length > 11) {
      phone_no = data.phone.slice(3);
    } else {
      phone_no = data.phone;
    }
    const params = {
      address_attributes: {
        address_line: data.address_line,
        area_id: +data.area_id,
        thana_id: +data.thana_id,
      },
      sales_mode: data.sales_mode,
      category: data.category,
      date_of_birth: new Date(data.date_of_birth),
      education: data.education,
      email: data.email,
      experience: data.experience,
      father_name: data.fathers_name,
      name: data.name,
      nid: data.nid,
      password: data.password,
      password_confirmation: data.password_confirmation,
      phone: phone_no,
      tech_skill: data.tech_skill,
      status: data.status,
      distributor_id: data.distributor_id,
    };
    putData2(`retailer_assistants/${id}`, params)
      .then((res) => {
        if (res.success) {
          history.push(`/ra/list`);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  if (getWarehouseType() == "central")
    return <Redirect to={{ pathname: "/ra/list" }} />;

  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Update Sales Representatives</h2>
        <div className="ml-auto">
          <Link
            to={`${process.env.PUBLIC_URL}/ra/list`}
            className="btn btn-sm btn-link"
          >
            <FaLongArrowAltLeft /> Back to List
          </Link>
        </div>
      </div>
      {!loading && (
        <div className="card-block">
          <div className="block-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group as={Row} controlId="ds">
                <Form.Label column sm="3">
                  Distributor <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    required
                    name="distributor_id"
                    {...register("distributor_id")}
                    as="select"
                    type="text"
                  >
                    <option value="">--Select--</option>
                    {distributors.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select distributor.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="thana">
                <Form.Label column sm="3">
                  Category <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    as="select"
                    type="text"
                    {...register("category")}
                    className={errors.category ? "is-invalid" : ""}
                  >
                    {Object.keys(categories).map((key, i) => (
                      <option key={i + 1} value={key}>
                        {humanize(key)}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.category?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* sales_mode */}
              <Form.Group as={Row} controlId="sales_mode">
                <Form.Label column sm="3">
                  Sales Mode <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    as="select"
                    type="text"
                    {...register("sales_mode")}
                    className={errors.sales_mode ? "is-invalid" : ""}
                  >
                    <option value="">--Select Sales Mode--</option>
                    <option value="all_sales">ALL</option>
                    <option value="pre_order">Pre Order</option>
                    <option value="prompt">Prompt Sale</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.sales_mode?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3">
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    {...register("name")}
                    type="text"
                    className={errors.name ? "is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="fathers_name">
                <Form.Label column sm="3">
                  Father's Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    type="text"
                    {...register("fathers_name")}
                    className={errors.fathers_name ? "is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fathers_name?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="email">
                <Form.Label column sm="3">
                  Email
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    type="email"
                    {...register("email")}
                    className={errors.email ? "is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="age">
                <Form.Label column sm="3">
                  Date of Birth <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Controller
                    control={control}
                    {...register("date_of_birth")}
                    render={({ field }) => (
                      <DatePicker
                        className={`form-control ${
                          errors.date_of_birth ? "is-invalid" : ""
                        }`}
                        placeholderText="Select birth date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date_of_birth?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="phone">
                <Form.Label column sm="3">
                  Phone Number <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    type="text"
                    {...register("phone")}
                    className={errors.phone ? "is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="education">
                <Form.Label column sm="3">
                  NID
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control {...register("nid")} type="number" />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="education">
                <Form.Label column sm="3">
                  Eduction <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    type="text"
                    {...register("education")}
                    className={errors.education ? "is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.education?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="tech_savvyness">
                <Form.Label column sm="3">
                  Tech Savvyness <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <textarea
                    {...register("tech_skill")}
                    row={5}
                    type="text"
                    className={`form-control ${
                      errors.tech_skill ? "is-invalid" : ""
                    }`}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tech_skill?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="experience">
                <Form.Label column sm="3">
                  Experience <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <textarea
                    type="text"
                    {...register("experience")}
                    row={5}
                    className={`form-control ${
                      errors.experience ? "is-invalid" : ""
                    }`}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.experience?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="thana">
                <Form.Label column sm="3">
                  Thana <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    {...register("thana_id")}
                    className={errors.thana_id ? "is-invalid" : ""}
                    as="select"
                    type="text"
                  >
                    <option value="">--Select Thana--</option>
                    {thanas.length > 0
                      ? thanas.map((item) => {
                          return (
                            <option
                              value={item.id}
                              key={item.id}
                              selected={thana === item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })
                      : ""}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.thana_id?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="area">
                <Form.Label column sm="3">
                  Area <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    {...register("area_id")}
                    className={errors.area_id ? "is-invalid" : ""}
                    as="select"
                    type="text"
                  >
                    <option value="">--Select Area--</option>
                    {areas.length > 0
                      ? areas.map((item) => {
                          return (
                            <option
                              value={item.id}
                              key={item.id}
                              selected={area === item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })
                      : ""}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    {errors.area_id?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                  Address<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    {...register("address_line")}
                    rows={2}
                    as="textarea"
                    type="text"
                    className={errors.address_line ? "is-invalid" : ""}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.address_line?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="status">
                <Form.Label column sm="3">
                  Status <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    {...register("status")}
                    as="select"
                    type="text"
                    className={errors.status ? "is-invalid" : ""}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <div className="mt-3 text-center">
                <Link
                  type="button"
                  className="btn btn-sm btn-btn-sm mr-2"
                  to={`${process.env.PUBLIC_URL}/ra/list`}
                >
                  <Button type="button" variant="danger">
                    Cancel
                  </Button>
                </Link>
                <Button size="btn-sm" type={"submit"} variant="primary">
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </App>
  );
};

export default Edit;
