import moment from "moment";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import App from "../../App";
import { getList } from "../../services";

const OutletSurvey = () => {
  const [surveyList, setSurveyList] = useState([]);
  useEffect(() => {
    getList("surveys").then((res) => {
      if (res?.success) {
        setSurveyList(res?.data);
      }
    });
  }, []);
  return (
    <App layout="">
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Outlet Survey</h2>
        </div>
      </div>
      <div className="card-block">
        <div className="block-body">
          <table className="order-table table table-striped">
            <thead>
              <tr>
                <th style={{ width: "20px" }}>#</th>
                <th>Outlet</th>
                <th>Phone</th>
                <th>Created By</th>
                <th>Photo</th>
                <th>Comment</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {surveyList && surveyList.length > 0 ? (
                surveyList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="order-item">{item.outlet.name}</div>
                      </td>
                      <td>
                        <div className="order-item">{item.outlet.phone}</div>
                      </td>
                      <td>
                        <div className="order-item">{item.created_by}</div>
                      </td>
                      <td>
                        <div className="order-item">
                          <Link to={`/outlet/survey/${item.id}`}>
                            <div className="user-avatar">
                              <img
                                src={item.photos[0].image_url}
                                alt="Photo"
                                style={{ width: "40px", height: "40px" }}
                              />
                            </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="order-item">{item.remarks}</div>
                      </td>
                      <td>
                        <div className="order-item">
                          {moment(item.created_at).format("DD-MM-YYYY")}
                        </div>
                      </td>
                      <td style={{ width: "150px" }} className={"nowrap"}>
                        <div className="btn-group">
                          <OverlayTrigger
                            key="view"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-view`}>View</Tooltip>
                            }
                          >
                            <Link
                              to={`/outlet/survey/${item.id}`}
                              className="btn btn-sm btn-default"
                            >
                              <FiEye />
                            </Link>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="9">No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </App>
  );
};

export default OutletSurvey;
