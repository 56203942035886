let api_base_url = null;
let ecom_url = null;
let promotion_url = null;
if (process.env.REACT_APP_ENV === "production") {
  api_base_url = process.env.REACT_APP_API_BASE_URL_PRODUCTION;
  ecom_url = process.env.REACT_APP_ECOM_URL_PRODUCTION;
  promotion_url = process.env.REACT_APP_PROMOTION_BASE_URL_PRODUCTION;
} else if (process.env.REACT_APP_ENV == "beta") {
  api_base_url = process.env.REACT_APP_API_BASE_URL_BETA;
} else if (
  process.env.REACT_APP_ENV === "pre_prod" ||
  process.env.REACT_APP_ENV === "auto-scalling"
) {
  api_base_url = process.env.REACT_APP_API_BASE_URL_PRE_PROD;
  ecom_url = process.env.REACT_APP_ECOM_URL_PRE_PROD;
} else if (process.env.REACT_APP_ENV === "staging") {
  api_base_url = process.env.REACT_APP_API_BASE_URL_STAGING;
  ecom_url = process.env.REACT_APP_ECOM_URL_STAGING;
  promotion_url = process.env.REACT_APP_PROMOTION_BASE_URL_DEVELOPMENT;
} else {
  api_base_url = process.env.REACT_APP_API_BASE_URL_DEVELOPMENT;
  ecom_url = process.env.REACT_APP_ECOM_URL_STAGING;
  promotion_url = process.env.REACT_APP_PROMOTION_BASE_URL_DEVELOPMENT;
}
export const API_BASE_URL = `${api_base_url}`;
export const ECOM_URL = `${ecom_url}`;
export const PROMOTION_URL = `${promotion_url}`;
export const DEBOUNCE_TIME = 500;
