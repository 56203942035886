import React, { Component } from "react";
import App from "../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiPlus, FiEdit, BiLayerPlus } from "react-icons/all";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from "react-toasts";
import "../../assets/scss/header.scss";
import { getPromotionList } from "../../services/baseServices";
import ConfirmationModal from "../common/Modal";
import Loader from "react-loader";

class List extends Component {
  constructor() {
    super();
    this.state = {
      promotions: [],
      activePage: 1,
      per_page: 25,
      page: 1,
      isLoading: false,
      temp_params: {},
      query_params: {},
    };
  }
  componentDidMount() {
    getPromotionList("promotions/")
      .then((res) => {
        this.setState({ promotions: res });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Promotion List</h2>
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/promotion/multicat/create`}
                className="btn btn-sm btn-secondary"
              >
                <FiPlus /> Add Promotion
              </Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body">
              <Loader loaded={!this.state.isLoading}>
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>#</th>
                      <th>Promotion Name</th>
                      <th>Active</th>
                      <th>Promotion Text</th>
                      <th>KPI Based</th>
                      <th style={{ width: "100px" }}>Start Date</th>
                      <th style={{ width: "100px" }}>End Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.promotions?.length > 0
                      ? this.state.promotions.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="order-item">{item.name}</div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item.is_active ? "Yes" : "No"}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item.promo_text ? item.promo_text : "N/A"}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item.is_kpi_based ? "YES ": "NO"}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">{item?.start}</div>
                              </td>
                              <td>
                                <div className="order-item">{item?.end}</div>
                              </td>
                              <td style={{ width: "150px" }}>
                                <div
                                  className="btn-group"
                                  style={{ marginLeft: "20px" }}
                                >
                                  {/* <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        Assign Product
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      to={`/supplier/assign-product/${item.id}`}
                                      className="btn btn-sm btn-default"
                                    >
                                      <BiLayerPlus />
                                    </Link>
                                  </OverlayTrigger> */}
                                  <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        Edit Promotion
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      to={`/promotion/multicat/edit/${item.id}`}
                                      className="btn btn-sm btn-default"
                                    >
                                      <FiEdit />
                                    </Link>
                                  </OverlayTrigger>
                                  {/* <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        Delete
                                      </Tooltip>
                                    }
                                  >
                                    <ConfirmationModal
                                      title="Delete"
                                      body="Are you sure you want to delete?"
                                      handleAction={() =>
                                        this.handleDeleteSupplier(item.id)
                                      }
                                    />
                                  </OverlayTrigger> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </Loader>
            </div>
            {/* <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.per_page}
                totalItemsCount={this.state.total_data}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div> */}
          </div>
        </div>
        <ToastsContainer store={ToastsStore} />
      </App>
    );
  }
}

export default withRouter(List);
