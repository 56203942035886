import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function ImageCarousel({ photos }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className="container"
      style={{
        width: "300px",
        height: "300px",
        background: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {photos && photos.length > 0 ? (
        photos.map((photo, idx) => (
          <Carousel.Item
            key={idx}
            style={{
              backgroundColor: "#fffff",
            }}
          >
            <img
              className="img-fluid w-100 d-block"
              src={photo.image_url}
              alt={`Slide ${idx + 1}`}
              style={{
                objectFit: "cover",
              }}
            />
          </Carousel.Item>
        ))
      ) : (
        <p>No photos available</p>
      )}
    </Carousel>
  );
}

export default ImageCarousel;
