import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FiPlus } from "react-icons/all";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import App from "../../App";
import { partnerTargetFormat } from "../../constants/csvHeader";
import { postData } from "../../services";
import ExportCSV from "../common/ExportCSV";
class PartnerOrderTargetCSV extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: null,
      uploading: false,
      selectedFileName: "",
    };
  }

  handleFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({
      selectedFile: file,
      selectedFileName: file ? file.name : "",
    });
    e.target.value = "";
  };

  handleUpload = () => {
    if (this.state.selectedFile && !this.state.uploading) {
      this.setState({ uploading: true });
      this.uploadCSV();
    } else {
      toast.error("Please select a CSV file to upload.");
    }
  };

  uploadCSV = () => {
    const formData = new FormData();
    formData.append("kpi_file", this.state.selectedFile);

    postData("/partner_kpis/bulk_upload", formData)
      .then((response) => {
        if (response?.success) {
          toast.info(response?.message);
          this.setState({
            selectedFile: null,
            selectedFileName: "",
            uploading: false,
          });
        } else {
          toast.error("Error uploading CSV");
          this.setState({
            uploading: false,
          });
        }
      })
      .catch((error) => {
        toast.error("Error uploading CSV");
        this.setState({
          selectedFile: null,
          selectedFileName: "",
          uploading: false,
        });
      });
  };

  render() {
    return (
      <App>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Partner Order Target </h2>
            <div className="ml-auto d-flex justify-content-center">
              <ExportCSV
                exportLabel="Template"
                data={[]}
                headers={partnerTargetFormat}
                title="Demo Partner Order Target CSV"
              />
              <label
                htmlFor="fileInput"
                className="btn btn-sm btn-secondary ml-2"
              >
                <FiPlus /> Select CSV file
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={this.handleFileChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            onClick={this.handleUpload}
            className="btn btn-sm btn-secondary ml-2"
            disabled={this.state.uploading || !this.state.selectedFile}
            style={{
              opacity:
                this.state.uploading || !this.state.selectedFile ? 0.5 : 1,
            }}
          >
            {this.state.uploading ? "Uploading..." : "Upload"}
          </Button>
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.state.selectedFileName && (
            <div className="ml-2">
              <b>Selected File: </b>
              {this.state.selectedFileName}
            </div>
          )}
        </div>
      </App>
    );
  }
}

export default withRouter(PartnerOrderTargetCSV);
