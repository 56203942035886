import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import styleReactSelect from "../../assets/styleReactSelect";
import "./form.css";
import {
  getDistributors,
  getOutlets,
  getChannels,
  getSKUListV2,
  getCompanies,
} from "../../services/baseServices";
import Select from "react-select";
import { ImCross, ImPlus } from "react-icons/all";

const initialSlabField = {
  ruleWeight: "",
  minWeight: "",
  maxWeight: "",
  discountType: "",
  discountRate: "",
};

function PromotionForm(props) {
  const [formFields, setFormFields] = useState([
    {
      selectedOptions: [],
      textValue: "",
    },
  ]);
  const [slabFields, setSlabFields] = useState([
    {
      ruleWeight: "",
      minWeight: "",
      maxWeight: "",
      discountType: "",
      discountRate: "",
    },
  ]);

  const promo_types = [
    {
      label: "generic",
      value: "generic",
    },
    {
      label: "SKU Weight",
      value: "sku_weight",
    },
  ];
  const [selectDistRef, setSelectDistRef] = useState(null);
  const [selectChannelRef, setSelectChannelRef] = useState(null);
  const [selectOutletRef, setSelectOutletRef] = useState(null);
  const [selectSkuRef, setSelectSkuRef] = useState(null);
  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [, setIsDistributorMulti] = useState(true);
  const [isChannelMulti, setIsChannelMulti] = useState(true);
  const [isOutletMulti, setIsOutletMulti] = useState(true);
  const [isSkuMulti, setIsSkuMulti] = useState(true);
  const discount_types = ["fixed", "percentage"];
  const [rule_type, setRule] = useState(props.formData?.rule_type || "");
  const [promo_type, setPromoType] = useState(props.formData?.promo_type || "");
  const [discount_type, setDiscountType] = useState(
    props.formData?.discount_type || ""
  );
  const [ruleList, setRuleList] = useState([]);
  const [distributorList, setDistributorList] = useState([]);
  const [outletList, setOutletList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [companieList, setCompanieList] = useState([]);
  const [freeSkuList, setFreeSkuList] = useState([]);
  const [promotionStartDate, setPromotionStartDate] = useState(
    props?.formData?.promotion_start_date
      ? props?.formData?.promotion_start_date
      : new Date()
  );
  const [promotionEndDate, setPromotionEndDate] = useState(
    props.formData?.promotion_end_date
      ? props.formData?.promotion_end_date
      : new Date()
  );

  const handleDistributorChange = (selectedOptions) => {
    if (selectedOptions) {
      selectedOptions.forEach((el) => {
        if (el.value === "all") {
          setSelectedDistributors([el]);
          props.handleSelectedChange([el], "selectedDistributors");
        } else {
          setSelectedDistributors(() => []);
          const tempArray = selectedOptions.filter(
            (item) => item.value !== "all"
          );
          props.handleSelectedChange([...tempArray], "selectedDistributors");
          setSelectedDistributors(() => [...tempArray]);
        }
      });
    } else {
      setSelectedDistributors([]);
      props.handleSelectedChange([], "selectedDistributors");
    }
  };
  const handleChannelChange = (selectedOptions) => {
    if (selectedOptions) {
      selectedOptions.forEach((el) => {
        if (el.value === "all") {
          setSelectedChannels([el]);
          props.handleSelectedChange([el], "selectedChannels");
        } else {
          setSelectedChannels(() => []);
          const tempArray = selectedOptions.filter(
            (item) => item.value !== "all"
          );
          props.handleSelectedChange([...tempArray], "selectedChannels");
          setSelectedChannels(() => [...tempArray]);
        }
      });
    } else {
      setSelectedChannels([]);
      props.handleSelectedChange([], "selectedChannels");
    }
  };
  const handleOutletChange = (selectedOptions) => {
    if (selectedOptions) {
      selectedOptions.forEach((el) => {
        if (el.value === "all") {
          setSelectedOutlets([el]);
          props.handleSelectedChange([el], "selectedOutlets");
        } else {
          setSelectedOutlets(() => []);
          const tempArray = selectedOptions.filter(
            (item) => item.value !== "all"
          );
          props.handleSelectedChange([...tempArray], "selectedOutlets");
          setSelectedOutlets(() => [...tempArray]);
        }
      });
    } else {
      setSelectedOutlets([]);
      props.handleSelectedChange([], "selectedOutlets");
    }
  };
  const handleSkuChange = (selectedOptions) => {
    if (selectedOptions) {
      selectedOptions.forEach((el) => {
        if (el.value === "all") {
          setSelectedOutlets([el]);
          props.handleSelectedChange(selectedSkus, "selectedSkus");
        } else {
          setSelectedSkus(() => []);
          const tempArray = selectedOptions.filter(
            (item) => item.value !== "all"
          );
          props.handleSelectedChange([...tempArray], "selectedSkus");
          setSelectedSkus(() => [...tempArray]);
        }
      });
    } else {
      setSelectedSkus([]);
      props.handleSelectedChange(selectedSkus, "selectedSkus");
    }
  };
  const handleRuleWeightChange = (textValue, index) => {
    setSlabFields((prevFormFields) => {
      const updatedFormFields = [...prevFormFields];
      updatedFormFields[index] = {
        ...updatedFormFields[index],
        ruleWeight: textValue,
      };
      return updatedFormFields;
    });
  };
  const handleMinWeightChange = (textValue, index) => {
    setSlabFields((prevFormFields) => {
      const updatedFormFields = [...prevFormFields];
      updatedFormFields[index] = {
        ...updatedFormFields[index],
        minWeight: textValue,
      };
      return updatedFormFields;
    });
  };
  const handleMaxWeightChange = (textValue, index) => {
    setSlabFields((prevFormFields) => {
      const updatedFormFields = [...prevFormFields];
      updatedFormFields[index] = {
        ...updatedFormFields[index],
        maxWeight: textValue,
      };
      return updatedFormFields;
    });
  };
  const handleDiscountTypeChange = (textValue, index) => {
    setSlabFields((prevFormFields) => {
      const updatedFormFields = [...prevFormFields];
      updatedFormFields[index] = {
        ...updatedFormFields[index],
        discountType: textValue,
      };
      return updatedFormFields;
    });
  };

  const handleDiscountRateChange = (textValue, index) => {
    setSlabFields((prevFormFields) => {
      const updatedFormFields = [...prevFormFields];
      updatedFormFields[index] = {
        ...updatedFormFields[index],
        discountRate: textValue,
      };
      return updatedFormFields;
    });
  };

  const handleSlabAddField = () => {
    setSlabFields([...slabFields, initialSlabField]);
  };

  const handleSlabRemoveField = (index) => {
    setSlabFields((prevFormFields) => {
      const updatedFormFields = prevFormFields.filter((_, i) => i !== index);
      return updatedFormFields;
    });
  };
  const isPromotionNameValid = (name) => {
    return name.length <= 500;
  };

  const isPromotionTextValid = (promoText) => {
    return promoText.length <= 1000;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.isEdit === false) {
      if (props.formData.promo_type === "sku_weight") {
        props.handleSlabList(slabFields);
      } else if (props.formData.promo_type === "generic") {
        props.handleGenericList();
      }
    }

    props.handleClick(e);
  };

  useEffect(() => {
    let isMounted = true; // Variable to track component mount status

    const fetchData = async () => {
      try {
        const distributors = await getDistributors();
        const outlets = await getOutlets();
        const channels = await getChannels();
        const skuList = await getSKUListV2();
        const companieList = await getCompanies()

        if (isMounted) {
          // Check if the component is still mounted before updating state
          let option = { value: "all", label: "All" };

          setDistributorList([
            option,
            ...distributors.map((el) => ({ value: el.id, label: el.name })),
          ]);
          setOutletList([
            option,
            ...outlets.map((el) => ({ value: el.id, label: el.name })),
          ]);
          setChannelList([
            option,
            ...channels.map((el) => ({ value: el.value, label: el.label })),
          ]);
          setSkuList(
            skuList.map((el) => ({ value: el.label, label: el.label }))
          );
          setCompanieList(
            companieList.map((el) => ({ value: el.value, label: el.label }))
          )
        }
      } catch (errMsg) {
        // Handle errors
      }
    };
    if (props.isEdit === false) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (props.formData.discount_type) {
      setDiscountType(props.formData.discount_type);
    }
    if (props.formData.discount_type === "free") {
      getSKUListV2()
        .then((res) => {
          let options = res.map((el) => {
            return { value: el.label, label: el.label };
          });
          setFreeSkuList(options);
        })
        .catch(() => {});
    }
  }, [props.formData.discount_type]);

  useEffect(() => {
    setPromotionStartDate(props.formData?.promotion_start_date);
    setPromotionEndDate(props.formData?.promotion_end_date);
  }, [props.formData, props.promotionInfo]);

  const handleFreeSkuChange = (selectedOption) => {
    props.setSelectedFreeSku(selectedOption);
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Promotion Name<span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"name"}
            placeholder="Please enter promotion name"
            onChange={props.handleInputOnChange}
            defaultValue={props.formData.name}
            type="text"
            isInvalid={!isPromotionNameValid(props.formData.name)}
          />
          <Form.Control.Feedback type="invalid">
            Promotion name must be 500 characters or less.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      {props.isEdit === false ? (
        <>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Promo Type<span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                required
                name="promo_type"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                value={props.formData.promo_type} // Assuming rule_type contains the selected value
                as="select"
                type="text"
                isInvalid={props.formData.promo_type === ""}
              >
                <option value="">--Select--</option>
                {promo_types.length > 0
                  ? promo_types.map((item, i) => {
                      return (
                        <option key={i} value={item.value}>
                          {item.label.toUpperCase()}
                        </option>
                      );
                    })
                  : ""}
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                Please select Promo Type.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Distributors
            </Form.Label>
            <Col sm="8" md={6} lg={7}>
              <Select
                ref={(ref) => {
                  setSelectDistRef(ref);
                }}
                name="selectedDistributor"
                isMulti={true}
                options={distributorList}
                onChange={(selectedOptions) =>
                  handleDistributorChange(selectedOptions)
                }
                styles={styleReactSelect}
                value={selectedDistributors}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Company<span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                required
                name="company_id"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                value={props.formData.company_id}
                as="select"
                type="text"
                isInvalid={props.formData.company_id === ""}
              >
                <option value="">--Select--</option>
                {companieList?.map((item, i) => {
                  return (
                    <option key={i} value={item.value}>
                      {item.label.toUpperCase()}
                    </option>
                  );
                })}
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                Please select Company.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Channels
            </Form.Label>
            <Col sm="8" md={6} lg={7}>
              <Select
                ref={(ref) => {
                  setSelectChannelRef(ref);
                }}
                name="selectedChannels"
                isMulti
                options={channelList}
                onChange={(selectedOptions) =>
                  handleChannelChange(selectedOptions)
                }
                styles={styleReactSelect}
                value={selectedChannels}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Outlets
            </Form.Label>
            <Col sm="8" md={6} lg={7}>
              <Select
                ref={(ref) => {
                  setSelectOutletRef(ref);
                }}
                name="selectedOutlets"
                isMulti={true}
                options={outletList}
                onChange={(selectedOptions) =>
                  handleOutletChange(selectedOptions)
                }
                styles={styleReactSelect}
                value={selectedOutlets}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              SKUs
            </Form.Label>
            <Col sm="8" md={6} lg={7}>
              <Select
                ref={(ref) => {
                  setSelectSkuRef(ref);
                }}
                name="selectedSKUs"
                isMulti
                options={skuList}
                onChange={(selectedOptions) => handleSkuChange(selectedOptions)}
                styles={styleReactSelect}
                value={selectedSkus}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Promotion Start Date
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <DatePicker
                name={"promotion_start_date"}
                className={`form-control`}
                selected={
                  promotionStartDate ? new Date(promotionStartDate) : new Date()
                }
                dateFormat="yyyy-MM-dd"
                onChange={(date) => props.setDate(date, "promotion_start_date")}
                minDate={new Date()}
                showTimeSelect={false}
                isClearable
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Is KPI Based
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Check
                name="is_kpi_based"
                type="switch"
                id={`custom-kpi-id`}
                checked={props.formData.is_kpi_based}
                onChange={(e) => {
                  props.handleIsActiveChange(e);
                }}
                style={{
                  marginRight: "200px",
                  transform: "scale(1.8)",
                  paddingLeft: "6.5rem",
                }}
              />
            </Col>
          </Form.Group>
        </>
      ) : (
        ""
      )}

      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Promotion End Date
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <DatePicker
            className="form-control"
            name={"promotion_end_date"}
            selected={
              promotionEndDate ? new Date(promotionEndDate) : new Date()
            }
            dateFormat="yyyy-MM-dd"
            onChange={(date) => props.setDate(date, "promotion_end_date")}
            minDate={
              promotionStartDate ? new Date(promotionStartDate) : new Date()
            }
            showTimeSelect={false}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Promotion Text<span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"promo_text"}
            required
            placeholder="Enter promotion text"
            onChange={(e) => {
              props.handleInputOnChange(e);
            }}
            defaultValue={props.promotionInfo.promo_text}
            type="text"
            isInvalid={!isPromotionTextValid(props.formData.promo_text)}
          />
          <Form.Control.Feedback type="invalid">
            Promotion text must be 1000 characters or less.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      {props.isEdit === true ? (
        <>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Status<span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Check
                name="isActive"
                type="switch"
                id={`custom-switch-id`}
                checked={props.formData.isActive}
                onChange={(e) => {
                  props.handleIsActiveChange(e);
                }}
                style={{
                  marginRight: "330px",
                  transform: "scale(1.8)",
                  paddingLeft: "6.5rem",
                }}
              />
            </Col>
          </Form.Group>
        </>
      ) : (
        ""
      )}

      {props.formData.promo_type === "sku_weight" && props.isEdit === false ? (
        <>
          {slabFields.map((field, index) => (
            <div className="form-box" key={index}>
              <>
                <>
                  {slabFields.length > 1 ? (
                    <Button
                      size="sm"
                      type={`button`}
                      variant="danger"
                      style={{ float: "right" }}
                      onClick={() => handleSlabRemoveField(index)}
                    >
                      <ImCross size={10} />
                    </Button>
                  ) : (
                    ""
                  )}
                </>

                <Form.Group
                  as={Row}
                  controlId="displayOrShopName"
                  className="mt-5"
                >
                  <Form.Label column sm="3">
                    Rule Weight<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"rule_weight"}
                      placeholder="Enter weight"
                      onChange={(e) => {
                        handleRuleWeightChange(e.target.value, index);
                      }}
                      type="text"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Minimum Weight<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"min_weight"}
                      placeholder="Enter weight"
                      onChange={(e) => {
                        handleMinWeightChange(e.target.value, index);
                      }}
                      defaultValue={props.promotionInfo.threshold}
                      type="text"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Maximum Weight<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"max_weight"}
                      placeholder="Enter weight"
                      onChange={(e) => {
                        handleMaxWeightChange(e.target.value, index);
                      }}
                      defaultValue={props.promotionInfo.threshold}
                      type="text"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Discount Type<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      required
                      name="discount_type"
                      onChange={(e) => {
                        handleDiscountTypeChange(e.target.value, index);
                      }}
                      as="select"
                      type="text"
                    >
                      <option value="">--Select--</option>
                      {discount_types.length > 0
                        ? discount_types.map((item, i) => {
                            return (
                              <option key={i} value={item}>
                                {item.toUpperCase()}
                              </option>
                            );
                          })
                        : ""}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                      Please select Rule.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Amount/Percentage<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"discount_amount"}
                      placeholder={
                        slabFields[index].discountType === "percentage"
                          ? "Enter percentage number only (e.g. 20)"
                          : "Enter amount only (e.g. 120)"
                      }
                      onChange={(e) => {
                        handleDiscountRateChange(e.target.value, index);
                      }}
                      type="text"
                    />
                  </Col>
                </Form.Group>
              </>
            </div>
          ))}
          <Form.Group
            as={Row}
            controlId="displayOrShopName"
            style={{ float: "right", marginRight: "20px" }}
          >
            <Button
              size="sm"
              type={`button`}
              variant="success"
              onClick={handleSlabAddField}
            >
              <ImPlus size={15} />
            </Button>
          </Form.Group>
        </>
      ) : (
        <>
          {rule_type === "sku_weight" || rule_type === "pack" ? (
            <Form.Group as={Row} controlId="displayOrShopName">
              <Form.Label column sm="3">
                Promo Weight<span className="text-danger">*</span>
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  name={"promo_weight"}
                  required
                  placeholder="Enter Promo Weight"
                  onChange={(e) => {
                    props.handleInputOnChange(e);
                  }}
                  defaultValue={props.promotionInfo.promo_weight}
                  type="text"
                />
              </Col>
            </Form.Group>
          ) : (
            ""
          )}
          {props.isEdit === false ? (
            <>
              <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                  Discount Type<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    required
                    name="discount_type"
                    onChange={(e) => {
                      props.handleInputOnChange(e);
                    }}
                    value={discount_type} // Assuming rule_type contains the selected value
                    as="select"
                    type="text"
                  >
                    <option value="">--Select--</option>
                    {discount_types.length > 0
                      ? discount_types.map((item, i) => {
                          return (
                            <option key={i} value={item}>
                              {item.toUpperCase()}
                            </option>
                          );
                        })
                      : ""}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please select Rule.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {discount_type === "free" ? (
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    SKU List<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Select
                      name="freeSkus"
                      required
                      options={freeSkuList}
                      onChange={handleFreeSkuChange}
                      styles={styleReactSelect}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter list.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Amount/Percentage<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"discount_amount"}
                      placeholder={
                        props.formData.discount_type === "percentage"
                          ? "Enter percentage number only (e.g. 20)"
                          : "Enter amount only (e.g. 120)"
                      }
                      onChange={(e) => {
                        props.handleInputOnChange(e);
                      }}
                      defaultValue={props.formData.discount_amount}
                      type="text"
                    />
                  </Col>
                </Form.Group>
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}

      <div className="mt-3">
        <Button
          size="lg"
          type={`submit`}
          disabled={props.isSubmitting}
          variant="primary"
        >
          {props.isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </div>
    </Form>
  );
  // }
}

export default PromotionForm;
