import React, { Component } from "react";
import "../assets/scss/sidebar-nav.scss";
import {
  FiChevronDown,
  FiChevronUp,
  FiFileText,
  GoDashboard,
  FiSettings,
} from "react-icons/all";
import { Link, withRouter } from "react-router-dom";

class CentralNavBar extends Component {
  state = {
    navBar: [
      {
        id: 1,
        text: "Dashboard",
        path: "/",
        icon: <GoDashboard />,
      },
      {
        id: 2,
        text: "Distribution House List",
        path: "/dwh/list",
        icon: <FiFileText />,
      },
      {
        id: 3,
        text: "Purchase Orders",
        path: "/purchaseOrder/list",
        icon: <FiFileText />,
      },
      {
        id: 4,
        text: "Stock Transfer Orders",
        path: "/dhPurchaseOrder/list",
        icon: <FiFileText />,
      },
      {
        id: 6,
        text: "Customer Orders",
        path: "/customerOrder/list",
        icon: <FiFileText />,
      },
      {
        id: 7,
        text: "Return Requests",
        path: "/returnOrder/list",
        icon: <FiFileText />,
      },

      {
        id: 8,
        text: "Products",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "List",
            path: "/product/list",
            icon: <FiFileText />,
          },

          {
            id: 2,
            text: "Add New",
            path: "/product/add-multicat-product",
            icon: <FiFileText />,
          },
          {
            id: 3,
            text: "View Custom Price",
            path: "/product/custom-product-price-list",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 9,
        text: "Bundle Products",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Pack Bundle",
            path: "/bundle/create-pack",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Pack List",
            path: "/bundle/list",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 10,
        text: "Temporary Images",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Temporary Image",
            path: "/temporaryImages",
          },
        ],
      },
      {
        id: 11,
        text: "Delivery Man",
        path: "/route/list",
        icon: <FiFileText />,
      },
      {
        id: 12,
        text: "Suppliers",
        path: "/supplier/list",
        icon: <FiFileText />,
      },
      {
        id: 13,
        text: "Partners",
        path: "/partner/list",
        icon: <FiFileText />,
      },
      {
        id: 14,
        text: "Outlet Survey",
        path: "/outlet/survey",
        icon: <FiFileText />,
      },
      {
        id: 15,
        text: "Sales Man",
        path: "/ra/list",
        icon: <FiFileText />,
      },
      // {
      //   id: 15,
      //   text: "Riders",
      //   path: "/rider/list",
      //   icon: <FiFileText />,
      // },
      {
        id: 16,
        text: "Promotions",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          // {
          //   id: 1,
          //   text: "Flash Sale",
          //   path: "/flashSale/list",
          //   icon: <FiFileText />,
          // },
          // {
          //   id: 2,
          //   text: "Coupon Generation",
          //   path: "/coupon/list",
          //   icon: <FiFileText />,
          // },
          // {
          //   id: 3,
          //   text: "Promotional Coupons",
          //   path: "/promo-coupon/list",
          //   icon: <FiFileText />,
          // },
          {
            id: 4,
            text: "Multicat Promotion",
            path: "/promotion/multicat/list",
            icon: <FiFileText />,
          },
          // {
          //   id: 5,
          //   text: "Others",
          //   path: "/promotions/list",
          //   icon: <FiFileText />,
          // },
        ],
      },
      {
        id: 17,
        text: "Categories",
        path: "/category/list",
        icon: <FiFileText />,
      },
      {
        id: 69,
        text: "Companies",
        path: "/company/list",
        icon: <FiFileText />,
      },
      {
        id: 19,
        text: "Sliders",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Common Sliders",
            path: "/common-slider/list",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Promotional Sliders",
            path: "/promotional-slider/list",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 20,
        text: "Location",
        path: "/location/list",
        icon: <FiFileText />,
      },
      {
        id: 21,
        text: "Inventory",
        path: "/inventory/list",
        icon: <FiFileText />,
      },
      {
        id: 22,
        text: "Qc Failed Items",
        path: "/qcFailedItems/list",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "PO Failed Items",
            path: "/po-failed-items/list",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "IGT Failed Items",
            path: "/igt-failed-items/list",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 23,
        text: "Brands",
        path: "/brands/list",
        icon: <FiFileText />,
      },
      {
        id: 24,
        text: "Blocked Items",
        path: "/blockItems/list",
        icon: <FiFileText />,
      },
      {
        id: 26,
        text: "Requested Product List",
        path: "/product-request/list",
        icon: <FiFileText />,
      },

      {
        id: 35,
        text: "Configurations",
        path: "#",
        icon: <FiSettings />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Shipping Methods",
            path: "/configurations/shipping_methods",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Commissions",
            path: "/configurations/commissions",
            icon: <FiFileText />,
          },
        ],
      },

      {
        id: 34,
        text: "Audit Logs",
        path: "/audit-logs/list",
        icon: <FiFileText />,
      },
      {
        id: 60,
        text: "Export & Import File",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Import DH Products",
            path: "/dh-assignment-upload",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Export DH Products",
            path: "/product/dh-assignment-view",
            icon: <FiFileText />,
          },
          {
            id: 3,
            text: "Upload Custom Price",
            path: "/bulk-product-price-upload",
            icon: <FiFileText />,
          },
        ],
      },
    ],
  };

  componentDidMount() {
    const parent_id = this.props.history?.location?.state?.parent_id;

    if (parent_id) {
      const navBar = { ...this.state.navBar };
      const itemObj = this.state.navBar.filter((obj) => {
        return obj.id === parent_id;
      });
      const index = this.state.navBar.indexOf(itemObj[0]);
      navBar[index].isCollapsed = false;
      this.setState(navBar);
    }

    const sortedNavBar = [...this.state.navBar];
    sortedNavBar.sort((a, b) => {
      if (a.text === "Dashboard") {
        return -1;
      } else if (b.text === "Dashboard") {
        return 1;
      } else {
        return a.text.localeCompare(b.text);
      }
    });

    this.setState({ navBar: sortedNavBar });
  }

  toggleSubNavItems = async (id) => {
    const navBar = { ...this.state.navBar };
    const itemObj = await this.state.navBar.filter((obj) => {
      return obj.id === id;
    });
    const index = this.state.navBar.indexOf(itemObj[0]);
    navBar[index].isCollapsed = !navBar[index].isCollapsed;
    this.setState(navBar);
  };

  render() {
    return (
      <div className="sidebar-nav collapsed-sidebar">
        <ul className="navigation">
          {this.state.navBar.map((item, i) => {
            if (item.hasSubMenu) {
              return (
                <li
                  key={i}
                  className={
                    item.isCollapsed
                      ? "has-sub-menu"
                      : "show-sub-menu has-sub-menu"
                  }
                >
                  <span
                    onClick={() => {
                      this.toggleSubNavItems(item.id);
                    }}
                    className="toggleCollapse nav-link"
                  >
                    <span className="nav-icon">{item.icon}</span>
                    <span className="nav-text">
                      <span className="text">{item.text}</span>
                      <span className="catet-icon">
                        {item.isCollapsed ? <FiChevronDown /> : <FiChevronUp />}
                      </span>
                    </span>
                  </span>

                  <ul className="sub-menu">
                    {item.subMenu.map((subItem, i) => {
                      return (
                        <li key={i}>
                          <Link
                            className={`nav-link ${
                              this.props?.history?.location?.pathname ===
                                subItem.path && "active-link"
                            } `}
                            to={{
                              pathname: subItem.path,
                              state: { parent_id: item.id },
                            }}
                          >
                            <span className="nav-text">{subItem.text}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            } else {
              return (
                <li key={i}>
                  <Link
                    className={`nav-link ${
                      this.props?.history?.location?.pathname === item.path &&
                      !this.props?.history?.location?.state &&
                      "active-link"
                    } `}
                    to={item.path}
                  >
                    <span className="nav-icon">{item.icon}</span>
                    <span className="nav-text">{item.text}</span>
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(CentralNavBar);
