const dmHeaders = [
  "title",
  "bn_title",
  "phone",
  "dm_point",
  "dm_name",
  "distributor_id",
  "group",
];

const srHeaders = [
  "name",
  "phone",
  "father_name",
  "experience",
  "education",
  "nid",
  "tech_skill",
  "date_of_birth",
  "category",
  "distributor_id",
];

const partnerHeaders = [
  "BATB Region",
  "BATB Area",
  "BATB Distribution House",
  "BATB Territory",
  "BATB Point",
  "BATB Route/Section",
  "BATB Route",
  "BATB Section",
  "BATB Retailer Code",
  "BATB Outlet Name",
  "BATB Owner Name",
  "BATB Contact Number",
  "BATB Channel",
  "BATB Sub-Channel",
  "BATB TPG",
  "BATB Cluster Name",
  "BATB Cluster Type",
  "BATB Geo Class",
  "Arla Distributor Code",
  "Arla Code",
  "Arla Code1",
  "Arla Short name",
  "Arla Name",
  "Arla Owner",
  "Arla Town Name",
  "Arla Address",
  "Arla Contact no",
  "Arla Channel",
  "Arla Route Code",
  "Arla Route Name",
  "Arla Location",
  "Arla Created Date",
  "dep_id",
  "section_id",
  "retailer_id",
  "Long",
  "Lat",
  "SR",
  "DM",
  "Credit",
];

const partnerTargetHeaders = [
  "partner_phone",
  "regular_order_target",
  "regular_target_discount",
  "high_order_target",
  "high_target_discount",
  "order_target_unit",
];

const cppHeaders = ["type", "phone", "sku", "price", "status"];

const dmFormat = dmHeaders.map((header) => ({ label: header, key: header }));
const srFormat = srHeaders.map((header) => ({ label: header, key: header }));
const partnerFormat = partnerHeaders.map((header) => ({
  label: header,
  key: header,
}));
const customProductPriceFormat = cppHeaders.map((header) => ({
  label: header,
  key: header,
}));

const partnerTargetFormat = partnerTargetHeaders.map((header) => ({
  label: header,
  key: header,
}));

export {
  customProductPriceFormat,
  dmFormat,
  partnerFormat,
  srFormat,
  partnerTargetFormat,
};
